import React, { FC, useEffect } from "react";
import {
	Autocomplete,
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SaveIcon from "@mui/icons-material/Save";

import { IDeviceRowDetails } from "..";
import { Controller, useForm } from "react-hook-form";
import { useGetAllCustomersQuery } from "../../../redux/reducers/settings.reducer";
import { LoadingButton } from "@mui/lab";
import { useCreateDeviceMutation, useEditDeviceMutation } from "../../../redux/reducers/devices.reducer";

interface IDeviceDetailsDialogProps {
	open: boolean;
	deviceDetails: IDeviceRowDetails | null;
	onClose: () => void;
}

interface IDeviceFormFields {
	device_name: string;
	serial_number: string;
	customer: number;
	local_ip: string;
	global_ip: string;
	barrier_type: number;
	release_version: string;
}

const DeviceDetailsDialog: FC<IDeviceDetailsDialogProps> = (props) => {
	const { open, deviceDetails, onClose } = props;

	// APIS
	// GET ALL CUSTOMERS
	const { data: getAllCustomersResponse } = useGetAllCustomersQuery();

	// CREATE DEVICE
	const [
		createDevice,
		{
			isLoading: createDeviceLoading,
			isSuccess: createDeviceSuccess,
			error: createDeviceError,
			reset: resetCreateDeviceState
		}
	] = useCreateDeviceMutation();

	// EDIT DEVICE
	const [
		editDevice,
		{ isLoading: editDeviceLoading, isSuccess: editDeviceSuccess, error: editDeviceError, reset: resetEditDeviceState }
	] = useEditDeviceMutation();

	const {
		reset,
		watch,
		control,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<IDeviceFormFields>();

	const barrierType = watch("barrier_type");

	function handleSubmitForm(formData: IDeviceFormFields) {
		if (deviceDetails) {
			editDevice({
				id: deviceDetails.id,
				data: {
					device_uuid: formData.serial_number,
					society: formData.customer,
					release_version: formData.release_version,
					device_name: formData.device_name,
					local_ipv4_address: formData.local_ip,
					global_ipv4_address: formData.global_ip,
					barrier_config: formData.barrier_type
				}
			});
		} else {
			createDevice({
				device_uuid: formData.serial_number,
				society: formData.customer,
				release_version: formData.release_version,
				device_name: formData.device_name,
				local_ipv4_address: formData.local_ip,
				global_ipv4_address: formData.global_ip,
				barrier_config: formData.barrier_type
			});
		}
	}

	useEffect(() => {
		if (open) {
			if (deviceDetails) {
				reset({
					device_name: deviceDetails.device_name,
					serial_number: deviceDetails.serial_number,
					customer: deviceDetails.customer_id,
					release_version: deviceDetails.release_version,
					local_ip: deviceDetails.local_ip,
					global_ip: deviceDetails.global_ip,
					barrier_type: deviceDetails.barrier_type
				});
			} else {
				reset({
					device_name: "",
					serial_number: "",
					customer: 0,
					release_version: "",
					local_ip: "",
					global_ip: "",
					barrier_type: 0
				});
			}
		} else {
			resetCreateDeviceState();
			resetEditDeviceState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceDetails, open, reset]);

	useEffect(() => {
		if (createDeviceSuccess) {
			onClose();
		}
	}, [createDeviceSuccess, onClose]);

	useEffect(() => {
		if (editDeviceSuccess) {
			onClose();
		}
	}, [editDeviceSuccess, onClose]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				{deviceDetails ? "Device Details" : "Add New Device"}

				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="device-name-input"
								error={!!errors.device_name}
								classes={{ root: "user-form-input-label-root" }}
							>
								Device Name
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="device-name-input"
								placeholder="Add device name"
								error={!!errors.device_name}
								helperText={
									errors.device_name && errors.device_name.message ? errors.device_name.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("device_name", { required: "Device name is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="serial-number-input"
								error={!!errors.serial_number}
								classes={{ root: "user-form-input-label-root" }}
							>
								Serial Number
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="serial-number-input"
								placeholder="Add serial number"
								error={!!errors.serial_number}
								helperText={
									errors.serial_number && errors.serial_number.message ? errors.serial_number.message.toString() : ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("serial_number", { required: "Serial Number is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="customer-input"
								error={!!errors.customer}
								classes={{ root: "user-form-input-label-root" }}
							>
								Customer
							</InputLabel>

							<Controller
								control={control}
								name="customer"
								render={({ field: { value, onChange } }) => (
									<Autocomplete
										fullWidth
										options={getAllCustomersResponse ?? []}
										getOptionLabel={(option) => option.name}
										getOptionKey={(option) => option.id}
										value={getAllCustomersResponse?.find((item) => item.id === value) ?? null}
										onChange={(_, updatedValue) => onChange(updatedValue?.id ?? null)}
										id="customer-input"
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												hiddenLabel
												size="small"
												variant="filled"
												placeholder="Select the customer"
												error={!!errors.customer}
												helperText={
													errors.customer && errors.customer.message ? errors.customer.message.toString() : ""
												}
												InputProps={{
													...params.InputProps,
													hiddenLabel: true,
													disableUnderline: true,
													classes: { root: "filled-input-root" }
												}}
											/>
										)}
									/>
								)}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="release-version-input"
								error={!!errors.release_version}
								classes={{ root: "user-form-input-label-root" }}
							>
								Release Version
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="release-version-input"
								placeholder="Add release version"
								error={!!errors.release_version}
								helperText={
									errors.release_version && errors.release_version.message
										? errors.release_version.message.toString()
										: ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("release_version", { required: "Release version is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="local-ip-input"
								error={!!errors.local_ip}
								classes={{ root: "user-form-input-label-root" }}
							>
								Local IP
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="local-ip-input"
								placeholder="Add local IP"
								error={!!errors.local_ip}
								helperText={errors.local_ip && errors.local_ip.message ? errors.local_ip.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("local_ip", { required: "Local IP is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<InputLabel
								required
								htmlFor="global-ip-input"
								error={!!errors.global_ip}
								classes={{ root: "user-form-input-label-root" }}
							>
								Global IP
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="global-ip-input"
								placeholder="Add global IP"
								error={!!errors.global_ip}
								helperText={errors.global_ip && errors.global_ip.message ? errors.global_ip.message.toString() : ""}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("global_ip", { required: "Global IP is required" })}
							/>
						</Grid>

						<Grid item xs={12}>
							<InputLabel
								required
								htmlFor="barrier-config-input"
								error={!!errors.barrier_type}
								sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
							>
								Barrier Type
							</InputLabel>

							<FormControl fullWidth>
								<RadioGroup row sx={{ gap: "2rem" }}>
									<FormControlLabel
										value={0}
										control={<Radio />}
										label="Single (Entry)"
										checked={Number(barrierType) === 0}
										{...register("barrier_type", { required: true })}
									/>

									<FormControlLabel
										value={1}
										control={<Radio />}
										label="Single (Exit)"
										checked={Number(barrierType) === 1}
										{...register("barrier_type", { required: true })}
									/>

									<FormControlLabel
										value={2}
										control={<Radio />}
										label="Double (Entry + Exit)"
										checked={Number(barrierType) === 2}
										{...register("barrier_type", { required: true })}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>

						{createDeviceError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
									{"data" in createDeviceError && createDeviceError.data
										? `${String(createDeviceError.data).charAt(0).toUpperCase()}${String(createDeviceError.data).slice(
												1
										  )}`
										: "Something went wrong"}
								</Typography>
							</Grid>
						) : null}

						{editDeviceError ? (
							<Grid item xs={12}>
								<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
									{"data" in editDeviceError && editDeviceError.data
										? `${String(editDeviceError.data).charAt(0).toUpperCase()}${String(editDeviceError.data).slice(1)}`
										: "Something went wrong"}
								</Typography>
							</Grid>
						) : null}

						<Grid item xs={12}>
							{deviceDetails ? (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
									{/* <LoadingButton variant="text" color="error" startIcon={<DeleteOutlineIcon />}>
										Delete Device
									</LoadingButton> */}

									<LoadingButton
										disableElevation
										variant="contained"
										color="primary"
										startIcon={<SaveIcon />}
										loadingPosition="start"
										type="submit"
										loading={editDeviceLoading}
									>
										Save
									</LoadingButton>
								</Box>
							) : (
								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
									<LoadingButton
										disableElevation
										variant="contained"
										color="success"
										sx={{ paddingX: 4 }}
										type="submit"
										loading={createDeviceLoading}
									>
										Save
									</LoadingButton>
								</Box>
							)}
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default DeviceDetailsDialog;
