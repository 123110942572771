import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ERROR_RESPONSE_API_TYPE_E, IAPIErrorResponse, IErrorResponse } from "../types";

type IErrorKeyMappings = {
	[type in ERROR_RESPONSE_API_TYPE_E]: {
		[key in string]: string;
	};
};

const errorKeyMappings: IErrorKeyMappings = {
	[ERROR_RESPONSE_API_TYPE_E.DEVICE_CREATE_EDIT]: {
		device_uuid: "serial number"
	}
};

export const convertToTitleCase = (text: string): string => {
	if (!text) return "";

	return text
		.toLowerCase()
		.split(" ")
		.map((word) => word.replace(word[0], word[0].toUpperCase()))
		.join(" ");
};

export const convertSnakeToTitleCase = (text: string): string => {
	if (!text) return "";

	return text
		.toLowerCase()
		.split("_")
		.map((word) => word.replace(word[0], word[0].toUpperCase()))
		.join(" ");
};

export const downloadImageFromUrl = (imageUrl: string, fileName: string) => {
	const downloadLinkElement = document.createElement("a");

	downloadLinkElement.href = imageUrl;
	downloadLinkElement.download = fileName;

	document.body.appendChild(downloadLinkElement);
	downloadLinkElement.click();

	downloadLinkElement.parentNode?.removeChild(downloadLinkElement);
};

export const handleTransformAPIErrorResponse = (response: FetchBaseQueryError): FetchBaseQueryError => {
	let errorMessage = "Something went wrong";

	if (response.data) {
		const errorResponse = response.data as IAPIErrorResponse;

		if ("detail" in errorResponse) {
			errorMessage = String(errorResponse.detail);
		} else if ("details" in errorResponse) {
			errorMessage = String(errorResponse.details);
		} else {
			const [error] = Object.values(errorResponse)[0];
			errorMessage = error;
		}
	}

	response.data = errorMessage;

	return response;
};

export const transformErrorResponse = (errorResponse: IErrorResponse, type: ERROR_RESPONSE_API_TYPE_E): string => {
	let message = "Something went wrong";
	const [errorKey, [errorMessage]] = Object.entries(errorResponse)[0];

	if (errorMessage) message = errorMessage;

	const errorKeys = errorKeyMappings[type];
	if (Object.keys(errorKeys).includes(errorKey)) {
		message = message.replace(/this field/gi, errorKeys[errorKey]);
		message = message.replace(new RegExp(errorKey.replace("_", " "), "gi"), errorKeys[errorKey]);
	}

	return message;
};
