import {
	ERROR_RESPONSE_API_TYPE_E,
	ICreateDeviceRequestData,
	IEditDeviceRequestData,
	IErrorResponse,
	IGetDevicesMetricsResponse,
	IGetDevicesRequestData,
	IGetDevicesResponse,
	IGetDeviceStatsResponseData
} from "../../types";
import { getAPIHeaderOptions, getDefaultParams } from "../../utils/apiUtils";
import { transformErrorResponse } from "../../utils/commonUtils";
import { queriesApi } from "./queries.reducer";

export const devicesApi = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET DEVICES
		getDevices: builder.query<IGetDevicesResponse, IGetDevicesRequestData>({
			query: (requestData) => ({
				url: "/devices/admin/device",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: { ...getDefaultParams(), ...requestData }
			})
		}),

		// GET DEVICES METRICS
		getDevicesMetrics: builder.query<IGetDevicesMetricsResponse, void>({
			query: () => ({
				url: "/devices/admin/device-metrics",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: getDefaultParams()
			})
		}),

		// CREATE DEVICE
		createDevice: builder.mutation<unknown, ICreateDeviceRequestData>({
			query: (requestData) => ({
				url: "/devices/admin/device-data",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: requestData
			}),
			transformErrorResponse: (response) => {
				if (response.data) {
					response.data = transformErrorResponse(
						response.data as IErrorResponse,
						ERROR_RESPONSE_API_TYPE_E.DEVICE_CREATE_EDIT
					);
				}

				return response;
			}
		}),

		// EDIT DEVICE
		editDevice: builder.mutation<unknown, IEditDeviceRequestData>({
			query: (requestData) => ({
				url: `/devices/admin/device-data/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			}),
			transformErrorResponse: (response) => {
				if (response.data) {
					response.data = transformErrorResponse(
						response.data as IErrorResponse,
						ERROR_RESPONSE_API_TYPE_E.DEVICE_CREATE_EDIT
					);
				}

				return response;
			}
		}),

		// GET DEVICE STATS
		getDeviceStats: builder.query<IGetDeviceStatsResponseData[], void>({
			query: () => ({
				url: "/analytics/admin/device-event-stats",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: getDefaultParams()
			}),
			transformResponse: (response: IGetDeviceStatsResponseData[]) => {
				for (const responseItem of response) {
					if (typeof responseItem.camera_names === "string") {
						responseItem.camera_names = JSON.parse(responseItem.camera_names);
					}
				}
				return response;
			}
		})
	})
});

export const {
	// GET DEVICES
	useGetDevicesQuery,
	// GET DEVICES METRICS
	useGetDevicesMetricsQuery,
	// CREATE DEVICE
	useCreateDeviceMutation,
	// EDIT DEVICE
	useEditDeviceMutation,
	// GET DEVICE STATS
	useGetDeviceStatsQuery
} = devicesApi;
